import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import api from '../api';
import ResultCard from '../components/ResultCard';
import SearchHeader from '../components/SearchHeader';

const styles = theme => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  item: {
    maxWidth: '20%',
    flexBasis: '20%',
    flexGrow: 0,
  },
});

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      q: '',
      message: '',
      results: [],
      isLoading: false,
      showGestures: false,
      showBounds: false,
    };

    this.onKeyDown = this.onKeyDown.bind(this);
    this.onQueryChange = this.onQueryChange.bind(this);
    this.onShowBoundsChange = this.onShowBoundsChange.bind(this);
    this.onShowGesturesChange = this.onShowGesturesChange.bind(this);
  }

  async onKeyDown(e) {
    const { q } = this.state;

    if (e.key === 'Enter') {
      if (q) {
        let results = [];
        let message = '';
        this.setState({ isLoading: true });
        try {
          results = await api.search(q);
          if (results.length === 0) {
            message = `Couldn't find apps that can "${q}"`;
          }
        } catch (err) {
          results = [];
          message = 'There was a problem with our servers.';
        }

        this.setState({
          results,
          message,
          isLoading: false,
        });
      } else {
        this.setState({ results: [], message: 'Please enter a task description.' });
      }
    }
  }

  onQueryChange(e) {
    const { value } = e.target;
    this.setState({ q: value });
  }

  onShowBoundsChange() {
    const { showBounds } = this.state;
    this.setState({ showBounds: !showBounds });
  }

  onShowGesturesChange() {
    const { showGestures } = this.state;
    this.setState({ showGestures: !showGestures });
  }

  render() {
    const { classes } = this.props;
    const {
      message,
      results,
      isLoading,
      showGestures,
      showBounds,
    } = this.state;

    return (
      <>
        <SearchHeader
          isLoading={isLoading}
          showBounds={showBounds}
          showGestures={showGestures}
          onKeyDown={this.onKeyDown}
          onQueryChange={this.onQueryChange}
          onShowBoundsChange={this.onShowBoundsChange}
          onShowGesturesChange={this.onShowGesturesChange}
        />
        <Container className={classes.container} maxWidth="xl">
          <Grid
            justify="center"
            spacing={2}
            container
          >
            {results.length
              ? results.map(result => (
                <Grid
                  className={classes.item}
                  key={result.packageName}
                  item
                >
                  <ResultCard
                    app={result}
                    screen={result.screens[0]}
                    showGestures={showGestures}
                    showBounds={showBounds}
                  />
                </Grid>
              ))
              : message}
          </Grid>
        </Container>
      </>
    );
  }
}

export default withStyles(styles)(Search);
