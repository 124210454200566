import axios from 'axios';

const baseURL = 'http://tidal.eaux.design';
const api = axios.create({ baseURL });

export default {
  async search(q) {
    const r = await api.get('/search/tool', { params: { q } });
    return r.data.results;
  },
};
