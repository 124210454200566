import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';

import Search from './routes/Search';
import Study from './routes/Study';

const App = () => (
  <BrowserRouter>
    <CssBaseline>
      <Route path="/" exact component={Search} />
      <Route path="/study" component={Study} />
    </CssBaseline>
  </BrowserRouter>
);

export default App;
