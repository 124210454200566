import React from 'react';
import PropTypes from 'prop-types';
import { fade, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';

const styles = theme => ({
  grow: {
    flexGrow: 1,
  },
  bar: {
    position: 'relative',
    width: 'auto',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
  },
  icon: {
    width: theme.spacing(6),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputBase: {
    color: 'inherit',
  },
  input: {
    padding: theme.spacing(1, 1, 1, 6),
    width: 400,
  },
  controls: {
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
  },
});

const SearchHeader = (props) => {
  const {
    classes,
    isLoading,
    showBounds,
    showGestures,
    onKeyDown,
    onQueryChange,
    onShowBoundsChange,
    onShowGesturesChange,
  } = props;

  return (
    <AppBar position="static">
      <Toolbar>
        <div className={classes.grow} />
        <div className={classes.bar}>
          <div className={classes.icon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Find apps that can…"
            classes={{
              root: classes.inputBase,
              input: classes.input,
            }}
            onChange={onQueryChange}
            onKeyDown={onKeyDown}
          />
        </div>
        <div className={classes.grow} />
        <FormGroup className={classes.controls} row>
          <FormControlLabel
            control={<Switch checked={showGestures} onChange={onShowGesturesChange} />}
            label="Gestures"
          />
          <FormControlLabel
            control={<Switch checked={showBounds} onChange={onShowBoundsChange} />}
            label="Bounds"
          />
        </FormGroup>
      </Toolbar>
      {isLoading && <LinearProgress color="secondary" />}
    </AppBar>
  );
};

SearchHeader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  showBounds: PropTypes.bool.isRequired,
  showGestures: PropTypes.bool.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  onShowBoundsChange: PropTypes.func.isRequired,
  onShowGesturesChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(SearchHeader);
