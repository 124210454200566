import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  bound: {
    position: 'absolute',
    opacity: '0.5',
    backgroundColor: theme.palette.primary.main,
  },
});

const Bounds = (props) => {
  const { classes, bounds } = props;
  return bounds.map((bound, idx) => {
    const style = {
      left: `${bound[0] * 100}%`,
      top: `${bound[1] * 100}%`,
      width: `${(bound[2] - bound[0]) * 100}%`,
      height: `${(bound[3] - bound[1]) * 100}%`,
    };

    // eslint-disable-next-line react/no-array-index-key
    return <div className={classes.bound} key={idx} style={style} />;
  });
};

Bounds.propTypes = {
  bounds: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.number),
  ).isRequired,
};

export default withStyles(styles)(Bounds);
