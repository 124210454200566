import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ScoreIcon from '@material-ui/icons/Score';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';

import Screen from './Screen';

const ResultCard = (props) => {
  const {
    app,
    screen,
    showGestures,
    showBounds,
  } = props;

  return (
    <Card>
      <CardHeader
        avatar={<Avatar alt={app.name} src={app.icon} />}
        title={app.name}
      />
      <Screen
        screen={screen}
        showGestures={showGestures}
        showBounds={showBounds}
      />
      <CardContent>
        <List dense>
          <ListItem>
            <ListItemIcon>
              <TrendingUpIcon />
            </ListItemIcon>
            <ListItemText
              primary="Popularity"
              secondary={app.popularity}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <ScoreIcon />
            </ListItemIcon>
            <ListItemText
              primary="Score"
              secondary={app.score}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <ViewColumnIcon />
            </ListItemIcon>
            <ListItemText
              primary="Screen"
              secondary={screen.score}
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};

ResultCard.defaultProps = {
  showGestures: false,
  showBounds: false,
};

ResultCard.propTypes = {
  app: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    score: PropTypes.number.isRequired,
    popularity: PropTypes.number.isRequired,
  }).isRequired,
  screen: PropTypes.shape({
    url: PropTypes.string.isRequired,
    score: PropTypes.number.isRequired,
    gestures: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.number),
    ),
    matchingBounds: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.number),
    ),
  }).isRequired,
  showGestures: PropTypes.bool,
  showBounds: PropTypes.bool,
};

export default ResultCard;
