import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  gesture: {
    position: 'absolute',
    borderRadius: '50%',
    opacity: '0.8',
    backgroundColor: theme.palette.secondary.main,
    transform: 'translate(-50%, -50%)',
  },
});

const Gestures = (props) => {
  const { classes, gestures } = props;
  return gestures.map((gesture, idx) => {
    const diameter = `${10 * (0.9 ** idx)}%`;
    const style = {
      left: `${gesture[0] * 100}%`,
      top: `${gesture[1] * 100}%`,
      width: diameter,
      paddingTop: diameter,
    };

    /* eslint-disable-next-line react/no-array-index-key */
    return <div className={classes.gesture} key={idx} style={style} />;
  });
};

Gestures.propTypes = {
  gestures: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.number),
  ).isRequired,
};

export default withStyles(styles)(Gestures);
