import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Screen from '../components/Screen';

import api from '../api';

const QUERIES = [
  [
    'watch videos',
    'watch movies',
    'listen to music',
    'browse forums',
    'play music',
    'listen to podcast',
    'identify a song',
    'get sports updates',
    'recipe videos',
    'search online',
  ],
  [
    'book a cab',
    'make reservation',
  ],
  [
    'set a reminder',
    'set reminder',
  ],
  [
    'make payments',
    'make a payment',
    'mobile payments',
    'paying people',
    'send money',
    'check bank account',
    'track spending',
    'deposit a check',
  ],
  [
    'message people',
    'message friends',
    'messaging',
    'text friends',
    'communicate with people',
    'chat with friends',
    'video chat',
    'send messages',
    'group chat',
  ],
  [
    'check the weather',
    'check weather',
    'what time is it',
  ],
  [
    'buy groceries',
    'order food',
  ],
  [
    'find deals',
    'find coupons',
  ],
  [
    'navigate',
    'navigation',
    'navigating to a destination',
    'get directions',
    'find directions',
  ],
  [
    'write email',
    'respond to emails',
    'check email',
  ],
  [
    'set an alarm',
    'set alarm',
    'set alarms',
  ],
  [
    'calendar',
    'view calendar',
    'create events',
    'calendar to do list',
    'to do list',
    'taking notes',
    'take notes',
    'add tasks',
    'check calendar',
    'check calendar events',
    'write down notes',
  ],
  [
    'view workout activity',
    'track workout',
    'track diet',
  ],
  [
    'take photos',
    'take pictures',
    'take a picture',
    'post a picture',
    'post pictures',
    'post updates',
  ],
  [
    'turn off lights',
  ],
  [
    'translate word',
    'translate words',
  ],
  [
    'transit time',
  ],
  [
    'calculator',
  ],
  [
    'take measurements',
  ],
  [
    'show tickets',
  ],
  [
    'play games',
  ],
  [
    'read books',
    'view document',
    'scan a document',
  ],
  [
    'practice flashcards',
  ],
];

const styles = theme => ({
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  titleText: {
    marginLeft: theme.spacing(1),
  },
  item: {
    maxWidth: '20%',
    flexBasis: '20%',
    flexGrow: 0,
  },
});

class Study extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
    };
  }

  async componentWillMount() {
    const promises = QUERIES.map(qs => api.search(qs[0]));
    const results = await Promise.all(promises);
    const data = results.reduce((acc, curr, idx) => {
      acc[QUERIES[idx][0]] = curr;
      return acc;
    }, {});
    this.setState({ data });
  }

  render() {
    const { classes } = this.props;
    const {
      data,
    } = this.state;

    return (
      <Container className={classes.container} maxWidth="xl">
        <Grid container spacing={6}>
          {Object.keys(data).map(query => (
            <Grid
              key={query}
              xs={12}
              item
            >
              <div className={classes.title}>
                <Typography variant="h4">
                  {query}
                </Typography>
              </div>
              <Grid container spacing={4}>
                {data[query].map(app => (
                  <Grid
                    key={app.packageName}
                    xs={12}
                    item
                  >
                    <div className={classes.title}>
                      <Avatar alt={app.name} src={app.icon} />
                      <Typography className={classes.titleText} variant="h6">
                        {app.name}
                      </Typography>
                    </div>
                    <Grid container spacing={2}>
                      {app.screens.map(screen => (
                        <Grid
                          className={classes.item}
                          key={screen.url}
                          item
                        >
                          <Screen screen={screen} />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Container>
    );
  }
}

export default withStyles(styles)(Study);
