import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import Bounds from './Bounds';
import Gestures from './Gestures';

const styles = {
  paper: {
    position: 'relative',
  },
  img: {
    width: '100%',
    height: '100%',
    display: 'block',
  },
};

const Screen = (props) => {
  const {
    classes,
    screen,
    showGestures,
    showBounds,
  } = props;

  return (
    <Paper className={classes.paper}>
      <img
        className={classes.img}
        src={screen.url}
        alt={screen.score}
      />
      {showGestures && <Gestures gestures={screen.gestures} />}
      {showBounds && <Bounds bounds={screen.matchingBounds} />}
    </Paper>
  );
};

Screen.defaultProps = {
  showBounds: false,
  showGestures: false,
};

Screen.propTypes = {
  screen: PropTypes.shape({
    url: PropTypes.string.isRequired,
    score: PropTypes.number.isRequired,
    gestures: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.number),
    ),
    matchingBounds: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.number),
    ),
  }).isRequired,
  showGestures: PropTypes.bool,
  showBounds: PropTypes.bool,
};

export default withStyles(styles)(Screen);
